import React, { FC } from "react";
import { sc } from "~/foundation/Jss";
import { TwoColumnProps } from "../generated-types";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { GenericHeader } from "~/foundation/Components/GenericHeader";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Flex } from "@chakra-ui/react";
import { themable } from "~/foundation/Theme";

const TwoColumn: FC<TwoColumnProps> = ({ rendering , fields }) => {
	return (
		<Ribbon py={["var(--chakra-sizes-modulePY__SM)", null, "var(--chakra-sizes-modulePY__MD)", null, "var(--chakra-sizes-modulePY__XL)"]}>
			<ContentWrapper py={["0", null]}>
				<GenericHeader {...fields}/>
			</ContentWrapper>
			<ContentWrapper px={['var(--chakra-sizes-pageMarginSM)', null, "16", null, '32']} py={["0", null]}>
				<Flex flexDirection={["column", null, "row"]} gap={["8", null, "0", null, "8"]}>
					<Flex className="two-column__left" flexBasis={["auto", null, "50%"]} justifyItems="flex-start" sx={{ '> div': { flexGrow: "1" } }}>
						<sc.Placeholder name={rendering.placeholderNames.two_column_left} rendering={rendering} />
					</Flex>
					<Flex className="two-column__right" flexBasis={["auto", null, "50%"]} justifyContent="flex-end" sx={{ '> div': { flexGrow: "1" } }}>
						<sc.Placeholder name={rendering.placeholderNames.two_column_right} rendering={rendering} />
					</Flex>
				</Flex>
			</ContentWrapper>
		</Ribbon>
	);
};

export default themable()(TwoColumn);